export const componentMap = {
    // General Element
    'header': () => import('~/components/builder/base/header.vue'),
    'paragraph': () => import('~/components/builder/base/paragraph.vue'),
    'image': () => import('~/components/builder/element/image.vue'),
    'navigation': () => import('~/components/builder/base/navigation.vue'),
    'usernav': () => import('~/components/builder/base/usernav.vue'),
    'slideshow': () => import('~/components/builder/element/slideshow.vue'),
    'lesson_widget': () => import('~/components/builder/element/lesson_widget.vue'),
    'lesson_widget_usage': () => import('~/components/builder/element/lesson_widget_usage.vue'),
    'preview': () => import('~/components/builder/element/preview.vue'),
    //Dashboard Element
    'enroll_list': () => import('~/components/builder/element/enroll_list.vue'),
    'enroll_counter': () => import('~/components/builder/element/enroll_counter.vue'),
    'enroll_transaction': () => import('~/components/builder/element/enroll_transaction.vue'),
    'user_profile': () => import('~/components/builder/element/user_profile.vue'),
    'news_ticker': () => import('~/components/builder/element/news_ticker.vue'),
    'user_welcome_message': () => import('~/components/builder/element/user_welcome_message.vue'),
    'iframe_internal': () => import('~/components/builder/element/iframe_internal.vue'),
    'html': () => import('~/components/builder/element/html.vue'),
    //Form Element
    'hidden': () => import('~/components/builder/element/form/hidden.vue'),
    'radiobox': () => import('~/components/builder/element/form/radiobox.vue'),
    'checkbox': () => import('~/components/builder/element/form/checkbox.vue'),
    'select': () => import('~/components/builder/element/form/select.vue'),
    'button': () => import('~/components/builder/element/form/button.vue'),
    'textarea': () => import('~/components/builder/element/form/textarea.vue'),
    'input': () => import('~/components/builder/element/form/input.vue'),
    'address': () => import('~/components/builder/element/form/address.vue'),
    'datetime': () => import('~/components/builder/element/form/datetime.vue'),
    'upload': () => import('~/components/builder/element/form/upload.vue'),
    'checkout_product': () => import('~/components/builder/element/form/checkout_product.vue'),
    'checkout_payment': () => import('~/components/builder/element/form/checkout_payment.vue'),
  };
  