/**
=======================================================================
Builder Data Processing Function
=======================================================================
This function processes raw builder configuration data into a structured
format optimized for rendering dynamic layouts. It computes styles such
as padding, margin, border, and background for rows, columns, and objects.
Additionally, it handles visibility settings and default value assignments
to ensure robustness across various configurations.
=======================================================================
*/

export function processBuilderData(builder) {
  return builder.map((row, rowIndex) => {
    //console.log("row",row);
    // Main border style and color properties
    const borderStyle = row.borderStyle || 'solid';
    const borderColor = row.borderColor || '';

    // Process row padding, margin, and other properties
    const rowOuterPadding = {
      left: row.paddingLeft || '0',
      top: row.paddingTop || '0',
      bottom: row.paddingBottom || '0',
      right: row.paddingRight || '0',
    };

    const rowInnerPadding = {
      left: row.paddingInnerLeft || '0',
      top: row.paddingInnerTop || '0',
      bottom: row.paddingInnerBottom || '0',
      right: row.paddingInnerRight || '0',
    };

    const rowMargin = {
      left: row.marginLeft || '0',
      top: row.marginTop || '0',
      bottom: row.marginBottom || '0',
      right: row.marginRight || '0',
    };

    const rowColumn = {
      desktop: row.col || '0',
      tablet: row.colTablet || '0',
      mobile: row.colMobile || '0',
    };

    const rowBorder = {
      style: borderStyle,
      color: borderColor,
      top: row.borderTopWidth || '0',
      left: row.borderLeftWidth || '0',
      right: row.borderRightWidth || '0',
      bottom: row.borderBottomWidth || '0',
      radius: {
        topLeft: row.borderRadiusTopLeft || '0',
        topRight: row.borderRadiusTopRight || '0',
        bottomRight: row.borderRadiusBottomRight || '0',
        bottomLeft: row.borderRadiusBottomLeft || '0',
      },
    };

    const rowPosition = row.position || 'relative';
    const rowWidth = row.width || 'max-w-full';
    const rowColCount = row.columns.length;
    const rowGapSize = row.gapSize || '0';
    const rowVisible = row.visible ?? true;
    const rowCol = row.col || '0';
    //const rowColTablet = row.colTablet || '0';
    //const rowColMobile = row.colMobile || '0';
    //console.log(row.colTablet)
    //console.log(row.colMobile)
    const rowBackground = {
      out: {
        type: row.bgType || 'color',
        color: row.bgColor || '',
        image: row.bgImage || '',
        gradientColor1: row.bgGradientColor1 || '',
        gradientColor2: row.bgGradientColor2 || '',
        video: row.bgVideo || '',
      },
      in: {
        type: row.bgInnerType || 'color',
        color: row.bgInnerColor || '',
        image: row.bgInnerImage || '',
        gradientColor1: row.bgInnerGradientColor1 || '',
        gradientColor2: row.bgInnerGradientColor2 || '',
        video: row.bgInnerVideo || '',
      },
    };

    // Define visibility settings for the row
    const rowVisibility = {
      mobile: row.mobile !== false,
      tablet: row.tablet !== false,
      laptop: row.laptop !== false,
      desktop: row.desktop !== false,
    };

    const processedColumns = row.columns.map((column) => {
      const columnBorderStyle = column.borderStyle || borderStyle;
      const columnBorderColor = column.borderColor || borderColor;

      const columnOuterPadding = {
        left: column.paddingLeft || '0',
        top: column.paddingTop || '0',
        bottom: column.paddingBottom || '0',
        right: column.paddingRight || '0',
      };

      const columnInnerPadding = {
        left: column.paddingInnerLeft || '0',
        top: column.paddingInnerTop || '0',
        bottom: column.paddingInnerBottom || '0',
        right: column.paddingInnerRight || '0',
      };

      const columnMargin = {
        left: column.marginLeft || '0',
        top: column.marginTop || '0',
        bottom: column.marginBottom || '0',
        right: column.marginRight || '0',
      };

      const columnBorder = {
        style: columnBorderStyle,
        color: columnBorderColor,
        top: column.borderTopWidth || '0',
        left: column.borderLeftWidth || '0',
        right: column.borderRightWidth || '0',
        bottom: column.borderBottomWidth || '0',
        radius: {
          topLeft: column.borderRadiusTopLeft || '0',
          topRight: column.borderRadiusTopRight || '0',
          bottomRight: column.borderRadiusBottomRight || '0',
          bottomLeft: column.borderRadiusBottomLeft || '0',
        },
      };

      const columnPosition = column.position || 'relative';
      const columnColspan = column.colspan || '1';
      const columnObjCount = column.object.length;
      const columnVisible = column.visible ?? true;

      const columnBackground = {
        out: {
          type: column.bgType || 'color',
          color: column.bgColor || '',
          image: column.bgImage || '',
          gradientColor1: column.bgGradientColor1 || '',
          gradientColor2: column.bgGradientColor2 || '',
          video: column.bgVideo || '',
        },
        in: {
          type: column.bgInnerType || 'color',
          color: column.bgInnerColor || '',
          image: column.bgInnerImage || '',
          gradientColor1: column.bgInnerGradientColor1 || '',
          gradientColor2: column.bgInnerGradientColor2 || '',
          video: column.bgInnerVideo || '',
        },
      };

      // Define visibility settings for the column
      const columnVisibility = {
        mobile: column.mobile !== false,
        tablet: column.tablet !== false,
        laptop: column.laptop !== false,
        desktop: column.desktop !== false,
      };

      // Process objects within the column
      const processedObjects = column.object.map((obj) => {
        const objectBorderStyle = obj.borderStyle || columnBorderStyle;
        const objectBorderColor = obj.borderColor || columnBorderColor;

        const objectBorder = {
          style: objectBorderStyle,
          color: objectBorderColor,
          top: obj.borderTopWidth || '0',
          left: obj.borderLeftWidth || '0',
          right: obj.borderRightWidth || '0',
          bottom: obj.borderBottomWidth || '0',
          radius: {
            topLeft: obj.borderRadiusTopLeft || '0',
            topRight: obj.borderRadiusTopRight || '0',
            bottomRight: obj.borderRadiusBottomRight || '0',
            bottomLeft: obj.borderRadiusBottomLeft || '0',
          },
        };

        // Define visibility settings for the object
        const objectVisibility = {
          mobile: obj.mobile !== false,
          tablet: obj.tablet !== false,
          laptop: obj.laptop !== false,
          desktop: obj.desktop !== false,
        };

        return {
          ...obj,
          padding: {
            left: obj.paddingLeft || '0',
            top: obj.paddingTop || '0',
            bottom: obj.paddingBottom || '0',
            right: obj.paddingRight || '0',
          },
          margin: {
            left: obj.marginLeft || '0',
            top: obj.marginTop || '0',
            bottom: obj.marginBottom || '0',
            right: obj.marginRight || '0',
          },
          border: objectBorder,
          visible: objectVisibility, // Add visibility group to object
          flows: obj.flows
        };
      });

      return {
        padding: {
          out: columnOuterPadding,
          in: columnInnerPadding,
        },
        margin: columnMargin,
        position: columnPosition,
        colspan: columnColspan,
        objectCount: columnObjCount,
        objects: processedObjects, // Use processed objects with visibility
        background: columnBackground,
        border: columnBorder,
        show: columnVisible,
        visible: columnVisibility, // Add visibility group to column
      };
    });

    return {
      rowIndex,
      padding: {
        out: rowOuterPadding,
        in: rowInnerPadding,
      },
      col: rowCol,
      margin: rowMargin,
      position: rowPosition,
      width: rowWidth,
      gap: rowGapSize,
      columnCount: rowColCount,
      columns: processedColumns,
      background: rowBackground,
      border: rowBorder,
      column: rowColumn,
      visible: rowVisibility, // Add visibility group to row
      show:rowVisible,
      mode: row.mode || 'standard',
      customId: row.customId || '',
      dataMode: row.dataMode || '',
      dataSetName: row.dataSetName || '',
    };
  });
}
