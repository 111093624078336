/* 
=======================================================================
Flow Execution and Utility Functions
=======================================================================
This section defines the logic to execute various "flow" actions based on the provided configurations. Each flow type (e.g., database save, alerts, redirects, email sending) performs a specific task, ensuring flexibility and extensibility.
*/

// Main function to execute a series of flows
export async function runFlow(flows, context = {}) {
    for (const flow of flows) {
        const { type, options } = flow;

        switch (type) {
            case 'database':
                await saveToDB(options, context); // Save data to the database
                break;

            case 'alert':
                await showAlert(options); // Display an alert dialog
                break;

            case 'redirect':
                redirectToURL(options); // Redirect to a specific URL
                break;

            case 'email':
                await sendEmail(options, context); // Send an email
                break;

            case 'delay':
                await delay(options); // Introduce a delay
                break;

            case 'modal':
                await showModal(options, context); // Display a modal dialog
                break;

            case 'trigger':
                handleTriggerStep(options); // Trigger UI element updates
                break;

            default:
                console.warn(`Unknown flow type: ${type}`); // Handle unknown flow types
        }
    }
}

/* saveToDB
   Handles saving data to a specified database collection. */
async function saveToDB(options, context) {
    const { collection } = options;

    if (!collection) {
        console.error('saveToDB: Missing collection in options');
        return;
    }

    try {
        console.log(`Saving data to collection: ${collection}`, context);
    } catch (error) {
        console.error(`saveToDB failed: ${error.message}`);
    }
}

/* showAlert
   Displays a browser alert with the specified message. */
function showAlert(options) {
    const { text } = options;

    if (!text) {
        console.error('showAlert: Missing text in options');
        return Promise.resolve();
    }

    return new Promise((resolve) => {
        alert(text);
        resolve();
    });
}

/* redirectToURL
   Redirects the browser to a specified URL. */
function redirectToURL(options) {
    const { url } = options;

    if (!url) {
        console.error('redirectToURL: Missing URL in options');
        return;
    }

    console.log(`Redirect to: ${url}`);
}

/* sendEmail
   Sends an email using the provided options and context. */
async function sendEmail(options, context) {
    const { $sendEmail } = useNuxtApp();
    const { received, received_email, sender, subject, body } = options;

    if (!subject || !body) {
        console.error('sendEmail: Missing subject or body in options');
        return;
    }

    const mapReceivedFormat = mappingMessage(received, options.keyword, context);
    const mapReceived_emailFormat = mappingMessage(received_email, options.keyword, context);
    const mapSenderFormat = mappingMessage(sender, options.keyword, context);
    const mapSubjectFormat = mappingMessage(subject, options.keyword, context);
    const mapBodyFormat = mappingMessage(body, options.keyword, context);

    try {
        await $sendEmail({
            from: `${mapSenderFormat} <noreply@cloud-service.email>`,
            to: [`${mapReceivedFormat} <${mapReceived_emailFormat}>`],
            subject: mapSubjectFormat,
            plain: mapBodyFormat,
            data: { name: mapReceivedFormat, message: mapBodyFormat },
            html: mapBodyFormat,
        });
    } catch (error) {
        console.error(`sendEmail failed: ${error.message}`);
    }
}

/* delay
   Introduces a delay for a specified duration (in seconds). */
async function delay(options) {
    const { duration } = options;

    if (typeof duration !== 'number' || duration <= 0) {
        console.error('delay: Invalid duration in options');
        return;
    }

    console.log(`Delaying for ${duration} seconds...`);
    return new Promise((resolve) => setTimeout(resolve, duration * 1000));
}

/* mappingMessage
   Replaces placeholders in messages with corresponding context values. */
function mappingMessage(message, keyword, context, loggingEnabled = true) {
    if (typeof message !== 'string') throw new Error('Message must be a string');
    if (!Array.isArray(keyword)) throw new Error('Keyword must be an array');
    if (!Array.isArray(context)) throw new Error('Context must be an array');

    return message.replace(/\[(.+?)\]/g, (match, key) => {
        const keywordItem = keyword.find(item => item.name === key);
        if (!keywordItem) {
            if (loggingEnabled) console.warn(`No keyword found for placeholder: ${key}`);
            return `**${match}**`;
        }

        const contextItem = context.find(item =>
            item.rowIndex === keywordItem.rowIndex &&
            item.columnIndex === keywordItem.columnIndex &&
            item.obj === keywordItem.obj
        );
        if (!contextItem || contextItem.value == null) {
            if (loggingEnabled) console.warn(`No value found in context for keyword: ${key}`);
            return `**${match}**`;
        }

        return contextItem.value;
    });
}

/* showModal
   Displays a modal dialog with the specified title, message, and actions. */
async function showModal(options, context) {
    const { title, message, keyword, mappings, onSubmit } = options;

    if (!title && !message) {
        console.error('showModal: Missing title or message in options');
        return;
    }

    const mapMessageFormat = mappingMessage(message, keyword, context);
    const mapTitleFormat = mappingMessage(title, keyword, context);

    return new Promise((resolve) => {
        const dimBackground = document.createElement('div');
        dimBackground.innerHTML = `
            <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); z-index: 999;">
                <div style="position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background-color: white; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);z-index: 1000;width: 400px;border-radius: 5px;">
                    <div style="border-bottom: 1px solid #dcdcdc;padding: 10px;">
                        <h2 style="text-align: center;font-weight: 500;color: #4c4c4c;">${mapTitleFormat || ''}</h2>
                    </div>
                    <p style="text-align: center;color: rgb(59, 59, 59);padding: 20px;">${mapMessageFormat || ''}</p>
                    <div style="border-top: 1px solid #dcdcdc;padding: 10px;text-align: center;">
                        <button id="submitButton" style="padding: 10px 20px;background-color: #28a745;color: white;border: none;border-radius: 5px;cursor: pointer;">Submit</button>
                    </div>
                </div>
            </div>
        `;

        document.body.appendChild(dimBackground);
        dimBackground.querySelector('#submitButton').addEventListener('click', () => {
            if (typeof onSubmit === 'function') {
                onSubmit();
            }
            document.body.removeChild(dimBackground);
            resolve();
        });
    });
}

/* handleTriggerStep
   Adds or removes a CSS class from an element based on the provided options. */
function handleTriggerStep(options) {
    const { element, cssClass, mode } = options;

    if (!element) {
        console.error('handleTriggerStep: Missing element selector in options');
        return;
    }
    if (!cssClass) {
        console.error('handleTriggerStep: Missing CSS class in options');
        return;
    }

    const targetElement = document.querySelector(element);
    if (!targetElement) {
        console.error(`handleTriggerStep: Element "${element}" not found.`);
        return;
    }

    if (mode === 'add') {
        targetElement.classList.add(cssClass);
        console.log(`Added class "${cssClass}" to element "${element}".`);
    } else if (mode === 'remove') {
        targetElement.classList.remove(cssClass);
        console.log(`Removed class "${cssClass}" from element "${element}".`);
    } else {
        console.error(`handleTriggerStep: Invalid mode "${mode}". Use "add" or "remove".`);
    }
}
