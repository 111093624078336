<template>
  <!-- <pre>{{ props.config.status }}</pre> -->
  <div class="relative w-full" :id="config.pageID ?? config.id">
    <!-- 
    =======================================================================
    Dynamic wrapper that toggles between 'form' and 'div' elements based on the `mode` prop:
    =======================================================================
    - If `mode` is 'form':
      - Renders a <form> element.
      - Includes a @submit.prevent="handleSubmit" listener to prevent default form submission and execute custom logic.
    - If `mode` is not 'form':
      - Renders a <div> element for non-form functionality.
    - The v-bind="$attrs" directive passes any additional attributes dynamically, ensuring flexibility for the wrapper element.
    -->
    <component :is="mode === 'form' ? 'form' : 'form'" @submit.prevent="handleSubmit" v-bind="$attrs">
      <div v-if="showDebug" class="debug-panel">{{ config.id }}</div>
      <!-- 
      =======================================================================
      Loop through processedData to dynamically render rows
      =======================================================================
      Each row is rendered based on the `row.dataMode` and `row.show` properties.
      -->
      <template v-for="(row, rowIndex) in processedData" :key="row.customId || rowIndex">
      
        <div :id="row.customId" :style="$getRowOutStyles(row)" :class="$getVisibilityClasses(row)" class="row-container">
          <!-- 
          =======================================================================
          Section for rows with `dataMode === 'set'` and `show` set to true
          =======================================================================
          Includes a dataset table with options to save, edit, or delete rows.
          -->
          <template v-if="row.dataMode === 'set' && row.show">
            <div class="dataset-wraper">
              <div :style="$getRowInStyles(row)" class="dataset-content">
                <div class="text-center text-lg font-semibold mb-1 pt-8">{{row.dataSetName}}</div>
                <p class="text-center text-sm pb-4 text-gray-500">แบบฟอร์มนี้สามารถเพิ่มข้อมูลได้หลายรายการ โดยกรอกข้อมูลด้านล่างและกด Save Item เพื่อบันทึก และสามารถเพิ่มข้อมูลอื่นได้</p>
                <div class="table-container">
                  <table>
                    <thead class="text-sm">
                      <tr>
                        <th>#</th>
                        <th v-for="(header, index) in getTableHeaders(row.columns)" :key="index">
                          {{ header }}
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="dataset.length === 0">
                        <td :colspan="row.columns.length + 2" class="text-center text-gray-500 text-sm">
                          No data set item here
                        </td>
                      </tr>
                      <tr v-for="(item, itemIndex) in dataset" :key="itemIndex" class="text-sm">
                        <td :data-label="'#'">{{ itemIndex + 1 }}</td>
                        <td v-for="(value, key, colIndex) in item" :key="key" :data-label="getTableHeaders(row.columns)[colIndex]">
                          {{ formatTableCell(value) }}
                        </td>
                        <td :data-label="'Actions'" class="text-center">
                          <button @click="editRowData(row, itemIndex)" class="mr-2">
                            <i class="fas fa-edit text-xs"></i>
                          </button>
                          <button @click="deleteRowData(itemIndex)">
                            <i class="fas fa-trash text-xs"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div :style="$getRowInStyles(row)" class="dataset-content inner-form">
                <div :style="$getRowInStyles(row)" class="row-content">
                  <div v-for="(column, colIndex) in row.columns"
                    :key="column.text || colIndex"
                    :style="$getColumnStyles(column)"
                    :class="$getVisibilityClasses(column)"
                    class="column"
                  >
                    <div v-for="obj in column.objects" :key="`${obj.type}-${obj.id || obj.name || colIndex}`">
                      <Suspense>
                        <template #default>
                            <component
                            v-if="column.show"
                            v-model="obj.value"
                            :key="resetKey"
                            :is="loadComponent(obj)"
                            :data="obj"
                            :item="obj"
                            :ref="setObjectRef(obj)"
                            :class="$getVisibilityClasses(obj)"
                            :modelValue="obj.value"
                            :status="showActive ?? true"
                            @update:modelValue="(val) => handleModelUpdate(val, obj)"
                            @click="handleButtonAction"
                            :addressComponents="addressComponents"
                            :index="`${rowIndex}-${colIndex}-${objIndex}`"
                            :objectTypeIndex="objectTypeIndexes[obj.uid]"
                            />
                        </template>
                        <template #fallback>
                          <div></div>
                        </template>
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>

              <div :style="$getRowInStyles(row)" class="dataset-content">
                <div class="flex justify-between items-center space-x-4 bg-gray-50 border border-gray-200 p-2">
                  <!-- Save Button -->
                  <button @click="saveRowData(row)" class="px-4 py-2 bg-blue-500 text-white rounded text-sm">
                    <i class="fas fa-save text-md"></i> {{ currentEditIndex !== null ? 'แก้ไขข้อมูลผู้สมัคร' : 'เพิ่มข้อมูลผู้สมัคร' }}
                  </button>
                  <!-- Error Text -->
                  <div v-if="!hasDataInDataset" class="text-sm text-red-500 text-right">
                    {{ row.dataSetName }} ยังไม่มีข้อมูล.
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- 
          =======================================================================
          Section for rows with `dataMode != 'set'` and `show` set to true
          =======================================================================
          Renders inputs or components dynamically without the dataset table.
          -->
          <template v-if="row.dataMode != 'set' && row.show">
            <div :style="$getRowInStyles(row)" class="row-content ">
              <div v-for="(column, colIndex) in row.columns"
                :key="column.text || colIndex"
                :style="$getColumnStyles(column)"
                :class="$getVisibilityClasses(column)"
                class="column"
              >
                <div v-for="(obj, objIndex) in column.objects" :key="`${obj.type}-${obj.id || obj.name || colIndex}`">
                  <Suspense>
                    <template #default>
                        <component
                        v-if="column.show"
                        v-model="obj.value"
                        :key="resetKey"
                        :is="loadComponent(obj)"
                        :data="obj"
                        :item="obj"
                        :ref="setObjectRef(obj)"
                        :class="$getVisibilityClasses(obj)"
                        :modelValue="obj.value"
                        :status="showActive ?? true"
                        @update:modelValue="(val) => handleModelUpdate(val, obj)"
                        @click="handleButtonAction"
                        :addressComponents="addressComponents"
                        :index="`${rowIndex}-${colIndex}-${objIndex}`"
                        :objectTypeIndex="objectTypeIndexes[`${rowIndex}-${colIndex}-${objIndex}`]"
                        class="render-wrapper"
                        />
                    </template>
                    <template #fallback>
                      <div></div>
                    </template>
                  </Suspense>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </component>
  </div>
</template>

<script setup>
import { ref, inject, onMounted, defineAsyncComponent, shallowRef } from 'vue';
import { processBuilderData } from '~/components/builder/plugin/builder.js';
import { componentMap } from '~/components/builder/plugin/componentMap.js';
import { runFlow } from '~/utils/flow';
import { validateForm } from '~/utils/valid';

/* 
=======================================================================
Component Props, Emits, and Reactive State Initialization
=======================================================================
This section defines the component's props, emits, and reactive state. It sets up the required props, events, and shared state for managing the dynamic behavior of the component. This includes reactive properties to handle datasets, form configuration, and overlay visibility.
*/

const props = defineProps({
  config: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  isActive: {
    type: Boolean,
    default:true,
    required: false,
  },
  mode: {
    type: String,
    default: 'standard',
    validator: (value) => ['standard', 'form'].includes(value),
  },
});

const resetKey = ref(0);
const emit              = defineEmits(['update:modelValue','click', 'update-builder-form']);
const showDebug         = inject('showDebug', ref(false));
const builder           = ref(props.config);
const processedData     = shallowRef([]);
const componentRefs     = ref({});
const dataset           = ref([]);
const setData           = ref({});
const currentEditIndex  = ref(null);
const authToken         = useCookie('_account');

// Computed property to determine if the overlay should be shown
const showAuthen = computed(() => props.mode === 'form' && !authToken.value);
const showActive = computed(() => {
  // Default to true if props or specific properties are not defined
  return (props?.mode === 'form' && props?.isActive) ?? true;
});

const hasDataInDataset = ref(false); // Tracks if dataset has data


const objectTypeIndexes = computed(() => {
  const indexes = {}; // เก็บ index สำหรับ type 'address'
  let addressCounter = 1; // เริ่ม index จาก 1

  processedData.value.forEach((row, rowIndex) => {
    row.columns.forEach((column, colIndex) => {
      column.objects.forEach((obj, objIndex) => {
        if (obj.type === 'address') {
          const key = `${rowIndex}-${colIndex}-${objIndex}`;
          indexes[key] = addressCounter++; // กำหนด index แล้วเพิ่มค่า
        }
      });
    });
  });

  //console.log("Generated Address Type Indexes:", indexes);
  return indexes;
});





/* 
=======================================================================
Utility Functions for Component Management and Data Retrieval
=======================================================================
These utility functions support dynamic component references, input data collection, and component loading.
*/

function setObjectRef(obj) {
  return (el) => {
    if (el) {
      //console.log(`Registering component ref:`, obj.name || obj.id, el);
      componentRefs.value[obj.id || obj.name] = el;
    } else {
      //console.warn(`Component ref is null for:`, obj.name || obj.id);
    }
  };
}

function grabAllInputData() {
  const allInputData = [];
  processedData.value.forEach((row, rowIndex) => {
    if (row.show) {
      row.columns.forEach((column, colIndex) => {
        column.objects.forEach((obj, objectIndex) => {
          // Push structured data into the result array, always accessing the latest value
          allInputData.push({
            rowIndex,
            columnIndex: colIndex,
            obj: objectIndex,
            name: obj.name || null,
            value: obj.value ?? null, // Ensure real-time value retrieval
            data: { ...obj }, // Spread the object to get the latest state
          });
        });
      });
    }
  });
  return allInputData;
}

function loadComponent(obj) {
  const componentName = obj.type;
  if (componentMap[componentName]) {
    return defineAsyncComponent(componentMap[componentName]);
  } else {
    console.warn(`Component ${componentName} not found in componentMap.`);
    return null;
  }
}

function rebuildComponentRefs() {
  componentRefs.value = {}; // Clear previous refs
  processedData.value.forEach((row) => {
    row.columns.forEach((column) => {
      column.objects.forEach((obj) => {
        setObjectRef(obj); // Re-register refs
      });
    });
  });
}

onUpdated(() => {
  rebuildComponentRefs(); // Update component refs
  watchLogicsOnMount(processedData.value); // Reinitialize logic
  runLogic(globalLogicPairs, processedData.value); // Apply logic
});


/* 
=======================================================================
Form Action Handling and Validation
=======================================================================
This section manages button actions, form submission, data validation, and related operations for dynamic forms. It includes logic to ensure data integrity and smooth user interaction.
*/

function mapPayloadToData(allData, mappings) {
  if (!Array.isArray(allData) || !Array.isArray(mappings)) {
    console.error("Invalid input: allData and mappings must be arrays.");
    return {};
  }
  const newData = {};
  mappings.forEach((mapping) => {
    const { keyword, values } = mapping;

    if (!keyword || !Array.isArray(values)) {
      console.warn("Invalid mapping structure:", mapping);
      return;
    }
    // Find the first matching value for the keyword
    for (const value of values) {
      if (
        value.rowIndex === undefined ||
        value.columnIndex === undefined ||
        value.obj === undefined
      ) {
        console.warn("Invalid value structure:", value);
        continue;
      }
      // Find the matching entry in allData
      const match = allData.find(
        (item) =>
          item.rowIndex === value.rowIndex &&
          item.columnIndex === value.columnIndex &&
          item.obj === value.obj
      );
      if (match) {
        // Assign the value to the keyword
        newData[keyword] = match.value || null;
        break; // Stop after the first match
      }
    }
    // If no match found, default to null
    if (!(keyword in newData)) {
      newData[keyword] = null;
    }
  });
  return newData;
}

async function handleButtonAction(event) {
  const { type, payload } = event;

  if (type === "submit") {
    const dataReturn = await handleSubmit(); // Await the result of handleSubmit
    if (dataReturn !== false) {
      const allData = grabAllInputData(); // Collect input data
      try {
        const mappedData = mapPayloadToData(allData, payload?.mappings);
        // Wrap mappedData with a key "mappedData"
        const wrappedMappedData = { mappedData };

        // Assign the wrapped data to dataReturn
        Object.assign(dataReturn, wrappedMappedData);

        if (payload?.flows) {
          await runFlow(payload.flows, allData); // Execute flows if available
        }
        
        //console.log("allData",allData);
        //console.log("mappedData",mappedData);
        //console.log("dataReturn",dataReturn);

        handleSubmitData(dataReturn);
        handleClear();
      } catch (error) {
        console.error("Error executing flow or fallback:", error);
      }
    } else {
      console.error("Form submission invalid.");
    }
  } else if (type === "clear") {
    handleClear();
  }
}

async function handleSubmit() {
  if (!props.isActive) {
    return false;
  }
  
  return new Promise((resolve) => {
    const formData = {};
    let globalIndex = 0;
    let isValid = true;
    const invalidFields = [];
    const allowedTypes = [
      "radiobox", "input", "checkbox", "textarea", "address",
      "select", "datetime", "upload", "hidden", "checkout_product", "checkout_payment"
    ];
    const formattedFormData = {};

    let hasRequiredDataset = false;
    let emptyDatasetNames = [];
    let standardInputErrors = [];

    processedData.value.forEach((row, rowIndex) => {
      if (row.dataMode === "set" && row.show) {
        hasRequiredDataset = true;
        if (dataset.value.length === 0) {
          emptyDatasetNames.push(row.dataSetName);
          isValid = false;
        } else {
          const setDataArray = dataset.value.map((item) => {
            let subIndex = 0;
            return row.columns.flatMap((column) =>
              column.objects.map((obj) => ({
                set: row.dataSetName,
                index: subIndex++,
                name: obj.name || obj.text,
                value: item[obj.name] ?? null,
                type: obj.inputType || obj.type,
                mode: obj.mode || obj.type,
              }))
            );
          });
          formattedFormData["setData"] = {
            [row.dataSetName]: setDataArray,
          };
        }
      } else if (row.dataMode != "set" && row.show) {
        row.columns.forEach((column, colIndex) => {
          column.objects.forEach((obj) => {
            if (obj && obj.name && allowedTypes.includes(obj.type)) {
              const key = `${obj.type}-${globalIndex}-${colIndex}-${rowIndex}`;
              formattedFormData[key] = {
                index: globalIndex,
                name: obj.name,
                value: obj.value ?? null,
                type: obj.inputType || obj.type,
                mode: obj.mode || obj.type,
              };

              const { isValid: fieldIsValid, errorMessage } = validateForm(
                obj,
                obj.value,
                invalidFields
              );  
              if (!fieldIsValid) {
                isValid = false;
                standardInputErrors.push({
                  fieldName: obj.label || obj.name,
                  message: errorMessage
                });

                const refComponent = componentRefs.value[obj.id || obj.name];
                if (refComponent && typeof refComponent.setError === "function") {
                  refComponent.setError(true, errorMessage);
                }
              }
              globalIndex++;
            }
          });
        });
      }
    });

    if (!isValid) {
      let errorMessages = [];
      
      // รวมข้อความ error จาก dataset ที่ว่างเปล่า
      if (emptyDatasetNames.length > 0) {
        errorMessages.push(`ข้อมูลที่จำเป็นต้องกรอก:\n${emptyDatasetNames.map(name => `• ${name}`).join("\n")}`);
      }

      // รวมข้อความ error จาก input ปกติ
      if (standardInputErrors.length > 0) {
        errorMessages.push(
          "ฟิลด์ที่ต้องตรวจสอบ:\n" +
          standardInputErrors.map(error => 
            `• ${error.fieldName}: ${error.message}`
          ).join("\n")
        );
      }

      // รวมข้อความ error ทั้งหมดและเพิ่มเครื่องหมายขีดเส้นคั่น
      const finalErrorMessage = errorMessages.join("\n\n----------------------------------------\n\n");
      
      // แสดงข้อความ error ในฟอร์ม
      if (finalErrorMessage) {
        Object.values(componentRefs.value).some((refComponent) => {
          if (refComponent && typeof refComponent.setFormError === "function") {
            refComponent.setFormError(finalErrorMessage);
          }
        });
      }
      
      console.error(finalErrorMessage);
      resolve(false);
      return;
    }
    const result = {
      formData: formattedFormData,
      formID: props.config._id,
      process: Array.isArray(props.config.status) && props.config.status.length > 0
        ? props.config.status[0].code // Access the `code` of the first item
        : "processing", // Default to "processing" if the array is empty or invalid
    };
    //console.log("Form submission result:", result);
    resolve(result); // Resolve with the result object for a valid form
  });
}

function handleClear() {
  // Increment resetKey to trigger re-render
  resetKey.value += 1;
  // Clear datasets
  dataset.value = [];
  setData.value = [];
  
  // Iterate through processedData and reset values
  processedData.value.forEach((row) => {
    row.columns.forEach((column) => {
      column.objects.forEach((obj) => {
        // Skip resetting if the object's type is 'hidden'
        if (obj.type === 'hidden') {
          return; // Skip this object
        }

        // Reset value in the reactive object
        obj.value = obj.defaultValue || ''; // Use a default value if provided, else empty

        // Reset the child component via refs if applicable
        const refComponent = componentRefs.value[obj.id || obj.name];
        if (refComponent && typeof refComponent.reset === 'function') {
          refComponent.reset(); // Call the component's reset method if it exists
        }
      });
    });
  });

  console.log("Forced re-render, cleared all input data, skipping 'hidden' inputs.");
}

function handleModelUpdate(newValue, target) {
  emit('update:modelValue', newValue);
  //console.log("target", target);
  // Check if target has logics and a destination object
  if (target && target.logics && target.logics.length > 0) {
    globalLogicPairs.forEach(({ sourceObj, destinationObj, logic }) => {
      const sourceValue = extractValue(sourceObj.value);
      //console.log(`Model updated for ${sourceObj.name}: ${sourceValue} - ${sourceObj.value.value}`);
      runLogic([{ sourceObj, destinationObj, logic }], processedData.value);
    });
  }
}

function handleSubmitData(data) {
  // Emit the message to the parent
  emit('update-builder-form', data)
}

/* 
=======================================================================
Dataset Management Functions
=======================================================================
These functions handle data operations for rows within a dynamic dataset. They manage saving, editing, deleting, and clearing row data, as well as formatting and retrieving table headers for display purposes.
*/

// Watcher for dataset changes
watch(dataset, (newVal) => {
  hasDataInDataset.value = newVal.length > 0;
}, { deep: true });

function saveRowData(row) {
  // Ensure 'required' property exists
  if (!props.isActive) {
    return false;
  }
  const newItem = {};
  let isValid = true;
  const invalidFields = [];

  // Iterate through each object in the row for validation
  row.columns.forEach((column) => {
    column.objects.forEach((obj) => {
      const value = obj.value || '';
      newItem[obj.name || obj.text] = value;

      // Use validateForm for validation
      const { isValid: fieldIsValid, errorMessage } = validateForm(obj, value, invalidFields);

      if (!fieldIsValid) {
        isValid = false;

        // Pass error messages to the component if applicable
        const refComponent = componentRefs.value[obj.id || obj.name];
        if (refComponent && typeof refComponent.setError === 'function') {
          refComponent.setError(true, errorMessage);
        }
      }
    });
  });

  // Prevent saving if any field is invalid
  if (!isValid) {
    console.error("Row validation failed. Invalid fields:", invalidFields);
    return;
  }

  // Save data if validation passes
  if (currentEditIndex.value !== null) {
    dataset.value[currentEditIndex.value] = newItem;
    currentEditIndex.value = null;
  } else {
    dataset.value.push(newItem);
  }

  // Clear form data after saving
  clearFormData(row);
}

function formatTableCell(value) {
  if (Array.isArray(value)) {
    return value.map(v => v.label || v.name || v).join(', ');
  } else if (typeof value === 'object' && value !== null) {
    return value.label || value.name || JSON.stringify(value);
  } else {
    return value;
  }
}

function getTableHeaders(columns) {
  return columns.map(column => {
    const objectNames = column.objects.map(obj => obj.name || obj.text || 'Unnamed').join(', ');
    return objectNames || column.text || 'Column';
  });
}

function clearFormData(row) {
  row.columns.forEach((column) => {
    column.objects.forEach((obj) => {
      obj.value = '';
    });
  });
}

function editRowData(row, index) {
  const itemToEdit = dataset.value[index];
  row.columns.forEach((column) => {
    column.objects.forEach((obj) => {
      obj.value = itemToEdit[obj.name || obj.text] || '';
    });
  });
  currentEditIndex.value = index;
}

function deleteRowData(index) {
  dataset.value.splice(index, 1);
}

/* 
=======================================================================
Logic Handling Functions
=======================================================================
These functions manage the conditional logic within the dataset, such as dynamically showing or hiding form fields based on user input. Logic pairs are built by analyzing source and destination objects defined in the dataset, and logic is executed by comparing the source values against expected conditions.
*/

let globalLogicPairs = [];

function getObjectByPath(path, dataset) {
  const { row, col, obj } = path;
  if (row === undefined || col === undefined || obj === undefined) {
    console.warn('Invalid path provided:', path);
    return null;
  }
  const target = dataset[row]?.columns[col]?.objects[obj] || null;
  if (!target) {
    console.warn(`Object not found at path [row:${row}, col:${col}, obj:${obj}]`);
  } else {
    //console.debug(`Found object at path [row:${row}, col:${col}, obj:${obj}]`, target);
  }
  return target;
}

function findDestinationFromLogic(logic, dataset) {
  return getObjectByPath({
    row: logic.rowIndex,
    col: logic.columnIndex,
    obj: logic.objectIndex
  }, dataset);
}

function buildLogicPairs(dataset) {
  globalLogicPairs = [];
  dataset.forEach((row, rowIndex) => {
    row.columns.forEach((column, columnIndex) => {
      column.objects.forEach((obj, objectIndex) => {
        if (obj.logics && obj.logics.length > 0) {
          obj.logics.forEach((logic) => {
            const sourceObj = getObjectByPath({ row: rowIndex, col: columnIndex, obj: objectIndex }, dataset);
            const destinationObj = logic.destination
              ? getObjectByPath({ row: logic.destination.rowIndex, col: logic.destination.columnIndex, obj: logic.destination.obj }, dataset)
              : findDestinationFromLogic(logic, dataset);

            if (sourceObj && destinationObj) {
              const isDuplicate = globalLogicPairs.some(pair =>
                (pair.sourceObj === destinationObj && pair.destinationObj === sourceObj) ||
                (pair.sourceObj === sourceObj && pair.destinationObj === destinationObj)
              );
              if (!isDuplicate) {
                //console.debug('Logic pair created:', { sourceObj, destinationObj, logic });
                globalLogicPairs.push({ sourceObj, destinationObj, logic });
              }
            } else {
              console.warn('Failed to create logic pair for logic:', logic);
            }
          });
        }
      });
    });
  });
  //console.log('All Logic Pairs:', globalLogicPairs);
  return globalLogicPairs;
}

function extractValue(value) {
  if (value && typeof value === 'object' && value.value) {
    return value.value;
  }
  const extracted = Array.isArray(value)
    ? value.map(v => v.label || v.name || v).join(', ')
    : (typeof value === 'object' && value !== null)
    ? (value.label || value.name || value)
    : value;
  //console.log('Extracted value:', extracted);
  return extracted;
}

function runLogic(logics, dataset) {
  logics.forEach(({ sourceObj, destinationObj, logic }) => {
    const { default: expectedValue, method } = logic;
    const sourceValue = extractValue(sourceObj.value);

    if (!sourceObj || !destinationObj) {
      console.warn('Source or Destination object not found for logic:', logic);
      return;
    }
    //console.log(`Running logic [${method}] - Expected: ${expectedValue}, Actual: ${sourceValue}`);
    const refComponent = componentRefs.value[destinationObj.id || destinationObj.name];

    if (method === 'show') {
      destinationObj.hidden = sourceValue !== expectedValue;
      if (refComponent && typeof refComponent.setVisibility === 'function') {
        refComponent.setVisibility(!destinationObj.hidden);
      }
      //console.log(`Setting visibility of destinationObj to ${!destinationObj.hidden}`);
    } else if (method === 'hide') {
      destinationObj.hidden = sourceValue === expectedValue;
      if (refComponent && typeof refComponent.setVisibility === 'function') {
        refComponent.setVisibility(!destinationObj.hidden);
      }
      //console.log(`Setting visibility of destinationObj to ${!destinationObj.hidden}`);
    }
  });
}

function watchLogicsOnMount(dataset) {
  buildLogicPairs(dataset);
}

// Extract all address components from processedData
const addressComponents = computed(() => {
  const addressData = [];
  processedData.value.forEach((row) => {
    row.columns.forEach((column) => {
      column.objects.forEach((obj) => {
        if (obj.type === 'address') {
          addressData.push(obj);
        }
      });
    });
  });
  return addressData;
});

onMounted(() => {
  //console.log('Component mounted. Initializing logic handling...');
  if (builder.value.builder) {
    processedData.value = processBuilderData(builder.value.builder);
    watchLogicsOnMount(processedData.value);
    runLogic(globalLogicPairs, processedData.value);
  }
});

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;300;400;500;700;900&display=swap');

/* Global font application */
* {
  font-family: 'Noto Sans Thai', sans-serif;
}

/* Debug panel styling */
.debug-panel {
  position: absolute;
  z-index: 9999;
  background: rgba(255, 0, 0, 0.53);
  color: rgba(255, 255, 255, 0.69);
  font-size: 12px;
  padding: 2px 5px;
  border: 1px solid rgba(255, 255, 255, 0.43);
  margin-top: 2px;
  margin-left: 2px;
}

/* Dataset content styling */
.dataset-content {
  display: block !important;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.dataset-content:first-of-type {
  border-top: 1px solid #ccc;
  padding-top: 10px !important;
}

.dataset-content:last-of-type {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px !important;
  margin-bottom: 10px;
}

.dataset-content .row-content {
  padding: 20px 0 !important;
}

/* Table container and styles */
.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile devices */
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #f4f4f4;
}

th,
td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Responsive styles for mobile screens */
@media screen and (max-width: 600px) {
  table.table-responsive {
    border: 0;
  }

  table.table-responsive thead {
    display: none;
  }

  table.table-responsive tbody,
  table.table-responsive tr,
  table.table-responsive td {
    display: block;
    width: 100%;
  }

  table.table-responsive tr {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }

  table.table-responsive td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    padding-right: 10px;
    font-size: 14px;
    border: none;
  }

  table.table-responsive td:first-child {
    background-color: #f4f4f4;
    font-weight: bold;
    padding: 10px;
  }

  table.table-responsive td:before {
    content: attr(data-label);
    font-weight: normal;
    text-transform: uppercase;
    flex-shrink: 0;
    color: #333;
    width: 40%;
    font-size: 14px;
    padding-left: 10px;
  }

  table.table-responsive td:last-child {
    border: none;
  }
}


/* Visibility classes */
.hidden {
  display: none !important;
}

/* Responsive visibility classes */
@media (max-width: 639px) {
  .hidden-mobile {
    display: none !important;
  }
  .visible-mobile {
    /*display: block !important;*/
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .hidden-tablet {
    display: none !important;
  }
  .visible-tablet {
    /*display: block !important;*/
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .hidden-laptop {
    display: none !important;
  }
  .visible-laptop {
    /*display: block !important;*/
  }
}

@media (min-width: 1280px) {
  .hidden-desktop {
    display: none !important;
  }
  .visible-desktop {
    /*display: block !important;*/
  }
}
/* UI blocking overlay */
.ui-hide {
  display: none;
}

.ui-blocking {
  position: relative;
}

.ui-blocking::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.ui-blocking::after {
  content: 'กำลังจัดการข้อมูล...';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  z-index: 2;
}

@media only screen and (max-width: 768px) {
  .render-wrapper {
    padding-left: 5px;
    padding-right: 5px;
  }

  .dataset-wraper .dataset-content {
    padding-left: 5px;
    padding-right: 5px;
  }
}


</style>
