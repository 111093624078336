export function validateForm(obj, value, invalidFields = []) {
  let hasError = false;
  let errorMessage = '';

  // ✅ ถ้าฟิลด์ถูกซ่อน ไม่ต้อง Validate
  if (obj.hidden) {
      return { isValid: true, errorMessage: '' };
  }

  // Ensure 'required' property exists
  if (obj.required === undefined) {
      console.warn(`Field "${obj.name || obj.text}" is missing 'required' property.`);
      obj.required = false;
  }

  // ✅ ข้าม Validation ถ้าไม่ใช่ฟิลด์ที่บังคับกรอก (required)
  if (!obj.required) {
      return { isValid: true, errorMessage: '' };
  }

  console.log(`Validating field: ${obj.name || obj.text || 'Unnamed Field'}`, obj);
  console.log(`Field value:`, value);

  if (obj.type === 'address') {
      const requiredAddressFields = ['province', 'district', 'subdistrict', 'zipcode'];
      hasError = (
          !value ||
          typeof value !== 'object' ||
          Object.keys(value).length === 0 ||
          requiredAddressFields.some(field => !value[field] || value[field] === '' || value[field] === null)
      );
      if (hasError) errorMessage = 'Please complete all address fields.';

  } else if (obj.type === 'radiobox') {
      hasError = (!value || value === '');
      if (hasError) errorMessage = obj.reqError ?? 'Please select an option.';

  } else if (obj.type === 'checkbox') {
      hasError = (!Array.isArray(value) ? !value : value.length === 0);
      if (hasError) errorMessage = obj.reqError ?? 'Please check at least one option.';

  } else if (obj.type === 'select') {
      hasError = (!value || value === '' || value === null);
      if (hasError) errorMessage = obj.reqError ?? 'Please select a value.';

  } else if (obj.type === 'input') {
      if (!value || value === '') {
          hasError = true;
          errorMessage = obj.reqError ?? 'This field is required.';
      } else {
          switch (obj.inputType) {
              case 'number':
                  hasError = isNaN(value);
                  if (hasError) errorMessage = obj.reqError ?? 'Please enter a valid number.';
                  break;
              case 'taxID':
                  hasError = !/^\d{13}$/.test(value || '');
                  if (hasError) errorMessage = obj.reqError ?? 'Tax ID must be exactly 13 digits.';
                  break;
              case 'mobile':
                  hasError = !/^\d{10}$/.test(value || '');
                  if (hasError) errorMessage = obj.reqError ?? 'Mobile number must be exactly 10 digits.';
                  break;
              case 'email':
                  hasError = !/^\S+@\S+\.\S+$/.test(value || '');
                  if (hasError) errorMessage = obj.reqError ?? 'Please enter a valid email address.';
                  break;
              case 'password':
                  hasError = typeof value !== 'string' || value.length < 6;
                  if (hasError) errorMessage = obj.reqError ?? 'Password must be at least 6 characters long.';
                  break;
              case 'name':
              case 'text':
              case 'taxName':
                  hasError = !value || value.toString().trim() === '';
                  if (hasError) errorMessage = obj.reqError ?? 'This field cannot be empty.';
                  break;
              default:
                  hasError = !value || value === '';
                  if (hasError) errorMessage = obj.reqError ?? 'Invalid input.';
                  break;
          }
      }
  } else {
      hasError = (!value || value === '');
      if (hasError) errorMessage = obj.reqError ?? 'This field is required.';
  }

  if (hasError) {
      const fieldName = obj.name || obj.text || 'Unnamed Field';
      console.warn(`Validation failed for field: ${fieldName}`);
      invalidFields.push(fieldName);
  }

  return { isValid: !hasError, errorMessage };
}
